import React from 'react';

import './Bullet.css';

function Bullet(props) {
  return(
    <div className="descriptor">
      <div className="descriptor-dash">-</div> 
      <div>{props.text}</div>
    </div>)
}

export default Bullet;