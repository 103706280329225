import React from 'react';

import './Tag.css';

function Tag(props) {
  let bgColor;
  let color;
  let innerText;
  switch(props.type) {
    case "html":
      bgColor = "#e34f26"
      color = "#f8f8f8"
      innerText = "HTML"
      break;
    case "js":
      bgColor = "#f7df1e"
      color = "#000000"
      innerText = "JavaScript"
      break;
    case "css":
      bgColor = "#000000"
      color = "#f8f8f8"
      innerText = "CSS"
      break;
    case "bash":
      bgColor = "#2B3439"
      color = "#f8f8f8"
      innerText = "BASH"
      break;
    case "python":
      bgColor = "#4584b6"
      color = "#f8f8f8"
      innerText = "Python"
      break;
    case "jupyter":
      bgColor = "#F47727"
      color = "#f8f8f8"
      innerText = "Jupyter"
      break;
    case "react":
      bgColor = "#00d8ff"
      color = "#f8f8f8"
      innerText = "React"
      break;
    case "firebase":
      bgColor = "#F57C00"
      color = "#f8f8f8"
      innerText = "Firebase"
      break;
    case "typescript":
      bgColor = "#007acc"
      color = "#f8f8f8"
      innerText = "TypeScript"
      break;
    case "redux":
      bgColor = "#764abc"
      color = "#f8f8f8"
      innerText = "Redux"
      break;
    case "heroku":
      bgColor = "#6762A6"
      color = "#f8f8f8"
      innerText = "Heroku"
      break;
    default:
      
  }
  return (
    <div style={{backgroundColor: bgColor, color: color, cursor: "default"}} className="tag">
      {innerText}
    </div>
  );
}

export default Tag;
