import React from 'react';
import { Motion,  spring } from 'react-motion';
import { Row, Col } from 'react-bootstrap';
import { FaBattleNet, FaHeart, FaJs, FaCss3, FaHtml5, FaReact, FaNode } from 'react-icons/fa';
import { IoLogoLinkedin, IoLogoGithub, IoLogoGoogle, IoLogoSteam } from 'react-icons/io';

import './Section.css';

import image01 from '../img/gtri.png';
import image02 from '../img/biosero.png';
// import image03 from '../img/exonbio.png';
import image04 from '../img/oracle.png';
import image05 from '../img/ugg.png';

import Tag from './Tag';
import Bullet from './Bullet';

function Section(props) {
  let innerHtml = (<div></div>)
  switch(props.type) {
    case "intro":
      innerHtml = (<div className="intro center">
        <h1 className="myName">Welcome to My Domain.</h1>
        <div className="intro-cont">
        {/* <span style={{fontSize: "2rem"}}>Hi. </span> */}
          <span>I'm Justin.</span> <br /><br />
          <span>I graduated from <span style={{color: "#006A96", fontWeight: "500"}}>UC San Diego</span> with a degree in Bioinformatics and a minor in Computer Science. <br /> <br />
          My hobbies include gaming, reading and playing piano.  
        {/* Recently, I've become interested in User Experience and Innovation. I'm excited to see what people are looking to disrupt next!  */}
          </span>
          <br /> <br />
          <div className="icon-cntr">
          <a href="https://steamcommunity.com/profiles/76561198221768576">
            <IoLogoSteam color={"#00adee"}/>
          </a>
          <a href="https://starcraft2.com/en-us/profile/1/1/1041476">
            <FaBattleNet color={"#1e71e7"}/>
          </a>
          <a href="https://www.linkedin.com/in/justin-ang/">
            <IoLogoLinkedin color={"#0077b5"}/>
          </a>
          <a href="https://github.com/justin8502">
            <IoLogoGithub color={"#333"}/>
          </a>
          <a href="mailto:justinang13@gmail.com">
            <IoLogoGoogle color={"#000000"}/>
          </a>
          </div>
        </div>
      </div>)
      break;
    case "projects":
      innerHtml = (
      <div>
      <div className="projects">
        <h1>Projects. </h1>
        <div className="section-cont">
          <Row className="proj-entry">
            <Col sm={12} md={5} className="proj-vital">
              <span className="title">
                Fox-Fire 
                <a href="https://github.com/Avid-Gaming-Esports/Fox-Fire/blob/master/constants.js">
                  <IoLogoGithub />
                </a>
              </span> 
              <div className="proj-tags">
              <Tag type="js"/>
              <Tag type="heroku"/>
              </div>
            </Col>
            <Col sm={12} md={7}  className="proj-desc">
              <span className="blurb">
                LoL Tournament Discord Bot
              </span>
              <br />
              <Bullet text="Researched and adapted Battlefy API / Puppeter to fully automate bot commands on Heroku instance. " />
            </Col>
          </Row>
          <Row className="proj-entry">
            <Col sm={12} md={5} className="proj-vital">
              <span className="title">
                Data-Loader
                <a href="https://github.com/Avid-Gaming-Esports/data-loader">
                  <IoLogoGithub/>
                </a>
              </span> 
              <div className="proj-tags">
              <Tag type="typescript"/>
              <Tag type="react"/>
              <Tag type="redux"/>
              <Tag type="html"/>
              <Tag type="css"/>
              <Tag type="js"/>
              </div>
            </Col>
            <Col sm={12} md={7}  className="proj-desc">
              <span className="blurb">
                LoL Custom Match Editor
              </span>
              <br /> 
              <Bullet text="Worked closely with Riot Developer API and DataDragon to translate and improve data. " />
              <Bullet text="Implemented support for various data formats (JSON, CSV) for flexibility. " />
            </Col>
          </Row>
          {/* <Row className="proj-entry">
            <Col sm={12} md={5} className="proj-vital">
              <span className="title">
                Airbnb Predictions 
                <a href="https://github.com/justinang1/CSE158PROJ2">
                  <IoLogoGithub/>
                </a>
              </span> 
              School Project
              <br />
              <div className="proj-tags">
              <Tag type="python"/>
              <Tag type="jupyter"/>
              </div>
            </Col>
            <Col sm={12} md={7}  className="proj-desc">
              <span className="blurb">
                Machine Learning Project predicting price/location. 
              </span>
              <br />
              <Bullet text="Cleaned and parsed raw open source listing data. " />
              <Bullet text="Observed and ranked correlation between listing features and pricing. " />
              <Bullet text="Utilized K-Means clustering to make price predictions. " />
            </Col>
          </Row> */}
          <Row className="proj-entry">
            <Col sm={12} md={5} className="proj-vital">
              <span className="title">
                KanbanTS
                <a href="https://github.com/justinang1/KanbanTS">
                  <IoLogoGithub/>
                </a>
              </span> 
              <div className="proj-tags">
              <Tag type="typescript"/>
              <Tag type="react"/>
              <Tag type="redux"/>
              <Tag type="html"/>
              <Tag type="css"/>
              <Tag type="js"/>
              </div>
            </Col>
            <Col sm={12} md={7}  className="proj-desc">
              <span className="blurb">
                Productivity Board
              </span>
              <br /> 
              <Bullet text="Implemented redux store to manage and edit tasks from different interfaces." />
              <Bullet text="Built out interfaces and managed types to ensure function call consistency. " />
            </Col>
          </Row>
          <Row className="proj-entry">
            <Col sm={12} md={5} className="proj-vital">
              <span className="title">
                TagLyne 
              </span> 
              <div className="proj-tags">
              <Tag type="react"/>
              <Tag type="redux"/>
              <Tag type="firebase"/>
              <Tag type="html"/>
              <Tag type="css"/>
              <Tag type="js"/>
              </div>
            </Col>
            <Col sm={12} md={7}  className="proj-desc">
              <span className="blurb">
                Event Manager
              </span>
              <br />
              <Bullet text="Set up/configured Google Cloud/Firebase hosting." />
              <Bullet text="Designed and developed web pages and user interfaces." />
            </Col>
          </Row>
          <Row className="proj-entry">
            <Col sm={12} md={5} className="proj-vital">
              <span className="title">
                Autojam 
                <a href="https://github.com/justinang1/autojam">
                  <IoLogoGithub />
                </a>
              </span> 
              <div className="proj-tags">
              <Tag type="bash"/>
              </div>
            </Col>
            <Col sm={12} md={7}  className="proj-desc">
              <span className="blurb">
                Drop Wifi Devices
              </span>
              <br />
              <Bullet text="Utilizes aircrack-ng suite and data pipelines to completely automate deauthentication. " />
            </Col>
          </Row>
        </div>
      </div>
      </div>)
      break;
    case "job":
      innerHtml = (
        <div>
        <h1>Work. </h1>
        <div className="section-cont">
          <Row className="work-entry">
            <Col lg={12} xl={3}>
              <img src={image05} alt="oracle" style={{width: 200, height: 100}}/>
            </Col>
            <Col lg={12} xl={6} className="work-desc">
              {/* <div className="work-location">Georgia Tech Research Institute</div> */}
              <div className="work-pos">Full-Stack Engineer</div>
              <Bullet text="Key contributor to release + maintenance of U.GG Desktop App, which reached over 1 million downloads. " />
              <Bullet text="Implement platform technology, APIs, internal tooling and modern ad tech. " />
              <Bullet text="Identify and resolve performance and scalability issues. " />
            </Col>
            <Col lg={12} xl={3} className="pos-format">
              <div><span className="work-duration">Spring 2021 - Present<br /> </span> </div>
              <div>Austin, TX</div>
            </Col>
          </Row>
          <Row className="work-entry">
            <Col lg={12} xl={3}>
              <img src={image04} alt="oracle" style={{width: 220, height: 40}}/>
            </Col>
            <Col lg={12} xl={6} className="work-desc">
              {/* <div className="work-location">Georgia Tech Research Institute</div> */}
              <div className="work-pos">Solutions Engineer</div>
              <Bullet text="Designed, built and reviewed customer integrations + data transformations" />
              <Bullet text="Gained hands-on experience with API Protocols and Data Formats (REST, JSON, SOAP and XML)" />
              <Bullet text="Worked closely with cross-functional teams (Sales, Project Management) to translate high-level feature requests into feasible design" />
            </Col>
            <Col lg={12} xl={3} className="pos-format">
              <div><span className="work-duration">Summer 2019 - Spring 2021<br /> </span> </div>
              <div>Santa Monica, CA</div>
            </Col>
          </Row>
          <Row className="work-entry">
            <Col lg={12} xl={3}>
              <img src={image01} alt="gtri" style={{width: 220, height: 50}}/>
            </Col>
            <Col lg={12} xl={6} className="work-desc">
              {/* <div className="work-location">Georgia Tech Research Institute</div> */}
              <div className="work-pos">Information Technology Intern</div>
              <Bullet text="Utilized JIRA to promote configuration management and development processes" />
              <Bullet text="Supplemented internal wiki (Confluence) with technical documentation, manuals and IT policies" />
            </Col>
            <Col lg={12} xl={3} className="pos-format">
              <div><span className="work-duration">Spring 2018 - Spring 2019<br /> </span> </div>
              <div>San Diego, CA</div>
            </Col>
          </Row>
          <Row className="work-entry">
            <Col lg={12} xl={3}>
              <img src={image02} alt="biosero"/>
            </Col>
            <Col lg={12} xl={6} className="work-desc">
              {/* <div className="work-location">Biosero</div> */}
              <div className="work-pos">Software Engineering Intern</div>
              <Bullet text="Debugged Green Button Go software to adapt to new hardware and improve performance" />
              <Bullet text="Determined appropriate manual or automated test strategy based on feature scope" />
            </Col>
            <Col lg={12} xl={3} className="pos-format">
              <div><span className="work-duration">Summer 2017 - Spring 2018<br /> </span> </div>
              <div>San Diego, CA </div>
            </Col>
          </Row>
          </div>
          </div>)
      break;
    case "footer":
      innerHtml = (<div className="footer center">
        
        <h3><span className="heart-format">Built with <FaHeart color="fd5c63"/> </span></h3><br /> 
        <h4>(and <FaHtml5 color="e34f26"/> <FaCss3 /> <FaJs color="f7df1e"/> <FaReact color="00d8ff"/> <FaNode color="215732"/>)</h4>
      </div>)
      break;
    default:
      
  }
  return (
    <Motion
      defaultStyle={{ opacity: 0 }}
      style={{ opacity: spring(1) }}
    >
      {style => 
      <div style={style}>
        {innerHtml}
      </div>}
    </Motion>
  );
}

export default Section;
