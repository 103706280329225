import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Section from './components/Section';

function App() {
  useEffect(() => {
    document.title = `Welcome to My Domain`;
  });

  return (
    <Container fluid className="app">
      <Row>
      <Col sm={0} md={1}>
      </Col>
      <Col sm={12} md={10}>
      <Section type="intro"/>
      </Col>
      <Col sm={0} md={1}>
      </Col>
      </Row>
      <Row>
        <Col className="color-top"></Col>
      </Row>
      <Row>
        <Col sm={0} md={1} className="colored">
        </Col>
        <Col sm={12} md={10} className="colored-body">
        <Section type="projects"/>
        </Col>
        <Col sm={0} md={1} className="colored">
        </Col>
      </Row>
      <Row>
        <Col className="color-bot"></Col>
      </Row>
      <Row>
      <Col sm={0} md={1}>
      </Col>
      <Col sm={12} md={10}>
      <Section type="job"/>
      </Col>
      <Col sm={0} md={1}>
      </Col>
      </Row>
      <Row>
      <Col sm={0} md={1}>
      </Col>
      <Col sm={12} md={10}>
      <Section type="footer"/>
      </Col>
      <Col sm={0} md={1}>
      </Col>
      </Row>
    </Container>
  );
}

export default App;
